import React from "react"
import * as THREE from "three"
import CANNON from "cannon"
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls.js"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import styled from "styled-components"
import Button from "../../components/Button"
import { Header } from "../../components/theme"
import { BackToLab } from "../../components/link"

const Description = styled.p`
  color: #fff;
  max-width: 340px;
`
const Highlight = styled.span`
  background: #f8ea00;
  padding: 2px 5px;
  color: #444;
  border-radius: 4px;
`
const Label = styled.div`
  color: #444;
  display: inline-block;
  margin-right: 8px;
  width: 100px;
`
const ControlBox = styled.div`
  background: #fff;
  padding: 4px;
  border-radius: 8px;
  margin-bottom: 16px;
  font-size: 14px;
  display: inline-block;
  line-height: 1.2;
  > div {
    vertical-align: middle;
    display: inline-block;
  }
  div:first-of-type {
    padding: 4px 8px;
  }
  div:last-of-type {
  }
  span {
    margin: 0 8px;
    width: 20px;
  }
  input {
    flex: 1;
  }
`
const Controls = styled.div`
  background: #444;
  display: flex;
  align-items: center;
  color: #fff;
  padding: 8px 16px;
  flex: 1;
  border-radius: 4px;
`
const ResetButton = styled.button`
  cursor: pointer;
  outline: none;
  vertical-align: text-bottom;
  font-size: 16px;
  margin-left: 8px;
  border: 1px solid #444;
  box-shadow: 3px 3px 0 #ccc;
  background: #fff;
  &:hover:enabled {
    transform: translate(2px, 2px);
    box-shadow: 2px 2px 0 #f97527;
  }
`
const TextBtn = styled(Button)`
  color: #fff;
  border-bottom: 2px solid #fff;
`

const w = 1
const h = 1
const d = 3

class Jenga extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      strength: 40,
      numberOfRows: 3,
      initCompleted: false,
      showDetails: true,
      config: {
        strength: true,
        numberOfRows: true,
      },
    }
    this.reset = this.reset.bind(this)
    this.updateStrength = this.updateStrength.bind(this)
    this.updateNumberOfRows = this.updateNumberOfRows.bind(this)
  }
  componentDidMount() {
    const sizes = {
      width: window.innerWidth,
      height: window.innerHeight,
    }
    this.objectsToUpdate = []
    const scene = new THREE.Scene()
    this.scene = scene

    const textureLoader = new THREE.TextureLoader()
    const ambientOcclusionMap = textureLoader.load(
      "/assets/textures/plywood-ambientOcclusion.jpg"
    )
    const baseColorMap = textureLoader.load(
      "/assets/textures/plywood-basecolor.jpg"
    )
    const normalMap = textureLoader.load("/assets/textures/plywood-normal.jpg")
    const roughnessMap = textureLoader.load(
      "/assets/textures/plywood-roughness.jpg"
    )

    const renderer = new THREE.WebGLRenderer()
    renderer.shadowMap.enabled = true
    renderer.shadowMap.type = THREE.PCFSoftShadowMap
    renderer.setSize(sizes.width, sizes.height)
    renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))

    this.mount.appendChild(renderer.domElement)

    /** Physics */

    const world = new CANNON.World()
    this.world = world
    world.broadphase = new CANNON.SAPBroadphase(world)
    world.gravity.set(0, -9.81, 0)
    world.allowSleep = true

    const contactMaterial = new CANNON.ContactMaterial(
      new CANNON.Material("floor"),
      new CANNON.Material("default"),
      {
        friction: 0.1,
        restitution: 0,
        contactEquationStiffness: 1e6,
      }
    )
    world.defaultContactMaterial = contactMaterial
    const floorBody = new CANNON.Body({ mass: 0 })
    floorBody.quaternion.setFromAxisAngle(
      new CANNON.Vec3(-1, 0, 0),
      Math.PI * 0.5
    )
    const floorShape = new CANNON.Plane()
    floorBody.addShape(floorShape)
    world.addBody(floorBody)

    /**
     * Lights
     */
    // Ambient light
    const ambientLight = new THREE.AmbientLight(0xffffff, 0.7)
    scene.add(ambientLight)

    const directionalLight = new THREE.DirectionalLight(0xffffff, 0.4)
    directionalLight.castShadow = true
    directionalLight.shadow.mapSize.set(1024, 1024)
    directionalLight.shadow.camera.far = 15
    directionalLight.shadow.camera.left = -7
    directionalLight.shadow.camera.top = 7
    directionalLight.shadow.camera.right = 7
    directionalLight.shadow.camera.bottom = -7
    directionalLight.position.set(5, 5, 5)
    scene.add(directionalLight)

    /**
     * Geometry
     */
    const floor = new THREE.Mesh(
      new THREE.PlaneGeometry(15, 15),
      new THREE.MeshStandardMaterial({
        color: "#333",
        metalness: 0.2,
        roughness: 0.5,
      })
    )
    floor.receiveShadow = true
    floor.rotation.x = -Math.PI * 0.5
    scene.add(floor)

    const boxGeometry = new THREE.BoxGeometry(1, 1, 1)
    const boxMaterial = new THREE.MeshStandardMaterial({
      metalness: 0.3,
      roughness: 0.8,
      aoMap: ambientOcclusionMap,
      aoMapIntensity: 1.5,
      normalMap,
      roughnessMap,
      map: baseColorMap,
    })

    const createBox = (width, height, depth, position) => {
      const boxMesh = new THREE.Mesh(boxGeometry, boxMaterial)
      boxMesh.scale.set(width, height, depth)
      boxMesh.castShadow = true
      boxMesh.receiveShadow = true
      boxMesh.position.copy(position)
      scene.add(boxMesh)

      const boxShape = new CANNON.Box(
        new CANNON.Vec3(width * 0.5, height * 0.5, depth * 0.5)
      )
      const boxBody = new CANNON.Body({ shape: boxShape, mass: 1 })
      boxBody.position.copy(position)
      world.add(boxBody)

      this.objectsToUpdate.push({
        mesh: boxMesh,
        body: boxBody,
      })
    }

    const offset = 0.1
    const setUpRow = (width, height, depth, positions) => {
      for (let i = 0; i < positions.length; i++) {
        setTimeout(() => {
          createBox(width, height, depth, positions[i])
        }, 200 * i)
      }
    }
    const setUpJenga = (current = 1) => {
      if (current > this.state.numberOfRows) {
        this.setState({
          initCompleted: true,
        })
        return
      }
      setTimeout(() => {
        const y = current * h
        if (current % 2 > 0) {
          setUpRow(w, h, d, [
            new THREE.Vector3(-w - offset * Math.random(), y, 0),
            new THREE.Vector3(0, y, 0),

            new THREE.Vector3(w + offset * Math.random(), y, 0),
          ])
        } else {
          setUpRow(d, w, h, [
            new THREE.Vector3(0, y, w + offset * Math.random()),
            new THREE.Vector3(0, y, 0),
            new THREE.Vector3(0, y, -w - offset * Math.random()),
          ])
        }
        return setUpJenga(current + 1)
      }, 800)
    }
    this.setUpJenga = setUpJenga

    /**
     * Camera
     */
    // Base camera
    const camera = new THREE.PerspectiveCamera(
      75,
      sizes.width / sizes.height,
      0.1,
      100
    )
    camera.position.set(
      -3,
      this.state.numberOfRows,
      this.state.numberOfRows + 4
    )
    // camera.zoom = 1
    // camera.updateProjectionMatrix()
    scene.add(camera)

    // Controls
    const controls = new OrbitControls(camera, renderer.domElement)
    controls.enableDamping = true
    controls.maxPolarAngle = Math.PI / 2 - 0.01
    controls.minDistance = 5
    controls.maxDistance = 14
    controls.target = new THREE.Vector3(0, 2, 0)

    const clock = new THREE.Clock()
    /** Raycaster */
    this.mouse = new THREE.Vector2()
    const raycaster = new THREE.Raycaster()

    let lastTime = 0
    const tick = () => {
      const elapsedTime = clock.getElapsedTime()
      let dt = elapsedTime - lastTime
      lastTime = elapsedTime

      raycaster.setFromCamera(this.mouse, camera)

      world.step(1 / 60, dt, 3)

      this.objectsToUpdate.forEach(obj => {
        obj.mesh.position.copy(obj.body.position)
        obj.mesh.quaternion.copy(obj.body.quaternion)
      })

      // Update controls
      controls.update()

      // Render
      renderer.render(scene, camera)

      // Call tick again on the next frame
      window.requestAnimationFrame(tick)
    }
    setUpJenga()
    tick()

    let currentIntesect

    const applyForce = obj => {
      obj.body.wakeUp()
      const x = camera.position.x > 0 ? 1 : -1
      const z = camera.position.z > 0 ? -1 : 1

      const pushInZ = x === z

      var impulse = new CANNON.Vec3(
        !pushInZ ? x * -1 * this.state.strength : 0,
        0,
        !pushInZ ? 0 : z * this.state.strength
      )
      obj.body.applyImpulse(impulse, obj.body.position)
      if (indicator) {
        scene.remove(indicator)
      }
    }
    this.click = event => {
      this.mouse.x = ((event.clientX - sizes.width * 0.5) * 2) / sizes.width
      this.mouse.y = (-(event.clientY - sizes.height * 0.5) * 2) / sizes.height
      this.clickEvent()
    }
    this.touch = event => {
      this.mouse.x =
        ((event.touches[0].clientX - sizes.width * 0.5) * 2) / sizes.width
      this.mouse.y =
        (-(event.touches[0].clientY - sizes.height * 0.5) * 2) / sizes.height
      this.clickEvent(true)
    }
    this.clickEvent = addIndicator => {
      let updateIntersect
      const intersects = raycaster.intersectObjects(
        this.objectsToUpdate.map(o => o.mesh)
      )
      if (intersects.length > 0) {
        updateIntersect = intersects[0]

        if (
          currentIntesect &&
          updateIntersect.object.uuid === currentIntesect.object.uuid
        ) {
          const intersect = this.objectsToUpdate.find(
            o => o.mesh.uuid === currentIntesect.object.uuid
          )
          applyForce(intersect)
        } else {
          currentIntesect = updateIntersect
          if (addIndicator) {
            this.setIndicator()
          }
        }
      }
    }
    window.addEventListener("touchstart", this.touch)
    window.addEventListener("click", this.click)

    let indicator

    this.mousemove = event => {
      this.mouse.x = ((event.clientX - sizes.width * 0.5) * 2) / sizes.width
      this.mouse.y = (-(event.clientY - sizes.height * 0.5) * 2) / sizes.height
      this.updateOnMouseMove()
    }

    this.setIndicator = () => {
      scene.remove(indicator)

      indicator = new THREE.Mesh(
        new THREE.BoxGeometry(
          currentIntesect.object.scale.x,
          currentIntesect.object.scale.y,
          currentIntesect.object.scale.z
        ),
        new THREE.MeshStandardMaterial({
          transparent: true,
          opacity: 0,
          color: "#fff",
          depthWrite: false,
        })
      )

      scene.add(indicator)

      indicator.position.copy(currentIntesect.object.position)
      indicator.quaternion.copy(currentIntesect.object.quaternion)
      indicator.material.opacity = 0.2
      indicator.scale.set(1.1, 1.1, 1.1)
    }

    this.updateOnMouseMove = () => {
      if (!this.state.initCompleted) return
      let updateIntersect
      const intersects = raycaster.intersectObjects(
        this.objectsToUpdate.map(o => o.mesh)
      )

      if (!intersects.length) {
        if (indicator) {
          scene.remove(indicator)
        }
        currentIntesect = undefined
      }

      if (intersects.length > 0) {
        updateIntersect = intersects[0]
        if (
          !currentIntesect ||
          updateIntersect.object.uuid !== currentIntesect.object.uuid
        ) {
          currentIntesect = updateIntersect

          this.setIndicator()
        }
      }
    }

    window.addEventListener("mousemove", this.mousemove)

    window.addEventListener("resize", () => {
      if (window.innerWidth >= 500) {
        // Update sizes
        sizes.width = window.innerWidth
        sizes.height = window.innerHeight

        // Update camera
        camera.aspect = sizes.width / sizes.height
        camera.updateProjectionMatrix()

        // Update renderer
        renderer.setSize(sizes.width, sizes.height)
        renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))
      }
    })
  }
  updateStrength(e) {
    this.setState({
      strength: e.target.value,
    })
  }
  updateNumberOfRows(e) {
    this.setState({
      numberOfRows: e.target.value,
    })
  }

  reset() {
    this.objectsToUpdate.forEach(o => {
      this.scene.remove(o.mesh)
      this.world.remove(o.body)
    })
    this.objectsToUpdate = []
    this.setState({
      initCompleted: false,
    })

    this.setUpJenga()
  }
  render() {
    const {
      strength,
      numberOfRows,
      initCompleted,
      showDetails,
      config,
    } = this.state
    const disabled = !initCompleted
    return (
      <div>
        <div
          ref={ref => (this.mount = ref)}
          style={{ position: "absolute", top: 0, left: 0 }}
        />
        <div
          style={{
            position: "absolute",
            zIndex: 2,
            padding: 8,
            left: 30,
            borderRadius: 8,
          }}
        >
          <BackToLab />

          <Header>Mini Jenga</Header>
          <div>
            <ResetButton disabled={disabled} onClick={this.reset}>
              Reset Tower
            </ResetButton>
          </div>
          <br />
          <ControlBox>
            <Label>Number of Levels</Label>
            {config.numberOfRows && (
              <Controls>
                <input
                  type="range"
                  min={3}
                  max={10}
                  step={1}
                  value={numberOfRows}
                  onChange={this.updateNumberOfRows}
                  disabled={disabled}
                />
                <span>{numberOfRows}</span>
              </Controls>
            )}
            <div>
              <Button
                style={{ fontSize: 14 }}
                disabled={disabled}
                onClick={() =>
                  this.setState(prev => ({
                    config: {
                      ...prev.config,
                      numberOfRows: !prev.config.numberOfRows,
                    },
                  }))
                }
              >
                {config.numberOfRows ? "hide" : "show"}
              </Button>
            </div>
          </ControlBox>
          <br />
          <ControlBox>
            <Label>Push Strength</Label>
            {config.strength && (
              <Controls>
                <input
                  type="range"
                  min={5}
                  max={50}
                  step={5}
                  value={strength}
                  onChange={this.updateStrength}
                  disabled={disabled}
                />
                <span id="strength">{strength}</span>
              </Controls>
            )}
            <div>
              <Button
                disabled={disabled}
                style={{ fontSize: 14 }}
                onClick={() =>
                  this.setState(prev => ({
                    config: {
                      ...prev.config,
                      strength: !prev.config.strength,
                    },
                  }))
                }
              >
                {config.strength ? "hide" : "show"}
              </Button>
            </div>
          </ControlBox>
          <br />
          {showDetails && (
            <Description>
              Thought it'd be fun to build Jenga with some degree of
              interactivity. Applied physics with cannon.js and it was so fun to
              work with{" "}
              <span role="img" aria-label="emoji">
                😍
              </span>
              ...Next iteration should be to add drag controls for the blocks
              out of the tower.
              <br />
              <br />
              <Highlight>Click on blocks</Highlight> to push them out. Try
              different push strength to control the magnitude.{" "}
              <Button
                href="https://3dtextures.me/2021/02/15/wood-plywood-001/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Credits: Plywood texture
              </Button>
            </Description>
          )}
          <TextBtn
            onClick={() =>
              this.setState(prev => ({ showDetails: !prev.showDetails }))
            }
          >
            {showDetails ? "OK. Forget it and play -" : "Show me the details +"}
          </TextBtn>
        </div>
      </div>
    )
  }
}
const JengaPage = ({ location }) => {
  return (
    <Layout location={location} navPosition="absolute">
      <SEO title="Mini Jenga" />
      <Jenga />
    </Layout>
  )
}
export default JengaPage
